

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    <loading-vue v-if="load"></loading-vue>
    
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                 <Notification />
    <div class="row">
    
                <div class="col-md-4">
                    <div>
    
                  
                        <div class="row">
                          <div class="col-12" v-for="(item, index) in items" :key="item.id">
    
    
    
                                    <div v-if="index  <  itemlist" class="card" :class="{active: addclass(item)}" @click="selectedelct(item)">
    
                                        <div class="card-body p-1">
                                            <div class="form-check custom-option custom-option-basic checked">
                                                <label class="form-check-label custom-option-content" :for="item.code">
                      
                        <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                        <span class="custom-option-body">
                          <strong class="fs-5">{{item.name}}</strong>
                        </span>
                      </label>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                                <div v-if="(items.length > itemlist) || (more == true)" @click="viewmore" class="d-flex justify-content-center mb-3">
                                    <b-button variant="primary" size="sm">{{more ? "less": "more"}}</b-button>
                                </div>
                        </div>
    
    
                        <!--end row-->
    
                        <!--end offcanvas-->
                    </div>
                </div>

                
                <div class="col-md-8">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Airtime</h4>
          
          </div>
          <!-- end card header -->
          <div class="card-body">
            
            <div class="live-preview">
              <form action="javascript:void(0);">
                <div class="mb-3">
                  <label for="phone" class="form-label"
                    >Phone Number</label
                  >
                  <input
                    type="text"
                    
                    v-model="phone"
                    class="form-control"
                    id="phone"
                    placeholder="Phone Number"
                  />
                </div>
                 <div class="mb-3 hstack gap-2 flex-wrap">
    
                                            <div class="form-check form-switch form-switch-lg" dir="ltr">
                                                <input type="checkbox" name="type" class="form-check-input" value="yes" id="postpaid" v-model="ported">
                                                <label class="form-check-label" for="yes">Ported Number ?</label>
                                            </div>
                                        </div>
                <div class="mb-3">
                  <label for="amount" class="form-label"
                    >Amount</label
                  >
                  <input
                    type="number"
                    class="form-control"
                    v-model="amount"
                    id="amount"
                    placeholder="Amount"
                  />
                </div>
                
                <div class="text-end">
                  <button type="submit" class="btn btn-primary" @click="processtransaction" :disabled="submitl">
                    <div class="spinner-border spinner-border-sm" role="status" v-if="submitl" > 
  <span class="sr-only">Loading...</span>
</div>
                    Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
    
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";


export default {
    page: {
        title: "Airtime",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            link: process.env.VUE_APP_URL,
            title: "Airtime",
            phone: null,
             amount: null,
            more: false,
            submitl: false,
            ported: false,
            itemlist: 3,
            selecteditem: null,
            load: false,
            items: [],
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue,
  
    },
    created() {
      this.getitems();
    },

    methods: {
   

     async processtransaction() {
              this.submitl = true;
      try {
          await axios.post(process.env.VUE_APP_MAIN_URL + "/process/airtime", {phone: this.phone, ported: this.ported, amount: this.amount, network: this.selecteditem.code}).catch((error) => {

            this.submitl = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                 this.submitl = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      } catch (error) {
            this.submitl = false;
             if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

              
      }


  
      },




       addclass(item) {


            return item == this.selecteditem;
        },

        viewmore() {

            if (this.more == false) {
                this.more = true;
                this.itemlist = 100

            } else {

                this.more = false;
                this.itemlist = 3

            }





        },
            selectedelct(item) {
            this.selecteditem = item;
        },
          async getitems() {
               this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/airtime").catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.items = response.data.data.data;


                }

            })
        },
    },
};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>